import { DropdownOption } from "@modernatx/ui-kit-react";
import { useRouter } from "next/router";
import React from "react";

import { useExperience } from "@/context/ExperienceContext";
import { FinderLocation } from "@/types/FinderLocation";

type SearchType = "unbranded" | "branded";

interface Place {
  address: string;
  id: string;
  latitude: number;
  longitude: number;
}

interface FinderContextValue {
  locationSelected: FinderLocation | null;
  locationSelectedSet: (location: FinderLocation) => void;
  place: Place | null;
  productsSelect: (products: string[]) => void;
  productsSelected: string[];
  searching: boolean;
  searchOptionSelect: (option: DropdownOption) => void;
  searchOptionSelected: DropdownOption | null;
  searchResults: FinderLocation[];
  searchType: SearchType;
  searchValue: string;
  searchValueSet: (value: string) => void;
}

const FinderContext = React.createContext<FinderContextValue>({
  locationSelected: null,
  locationSelectedSet: () => {},
  place: null,
  productsSelect: () => {},
  productsSelected: [],
  searching: false,
  searchOptionSelect: () => {},
  searchOptionSelected: null,
  searchResults: [],
  searchType: "branded",
  searchValue: "",
  searchValueSet: () => {}
});

const getProductsSelected = (query: ReturnType<typeof useRouter>["query"], products: string[]) => {
  const productsQuery = Array.isArray(query.products) ? query.products[0] : query.products;
  return productsQuery?.split(",").filter((p) => products.includes(p)) || [];
};

export const FinderContextProvider: React.FC<
  React.PropsWithChildren<{
    products: string[];
    searchType: SearchType;
    radius?: number;
  }>
> = ({ children, searchType, products, radius }) => {
  const { country, language, alternates } = useExperience();
  const { pathname, query, push, replace } = useRouter();
  const currentPathname = React.useMemo(() => {
    const alternateMatch = alternates?.find(
      (alternate) => alternate.country === country && alternate.language === language
    );
    if (!alternateMatch) {
      return pathname;
    } else {
      const { url } = alternateMatch;
      const newPathname = new URL(url).pathname;
      return newPathname;
    }
  }, [alternates, country, language, pathname]);
  const [locationSelected, locationSelectedSet] = React.useState<FinderLocation | null>(null);
  const [place, placeSet] = React.useState<null | Place>(null);
  const [productsSelected, productsSelectedSet] = React.useState<string[]>(
    getProductsSelected(query, products)
  );
  const [searching, searchingSet] = React.useState(false);
  const [searchOptionSelected, searchOptionSelect] = React.useState<DropdownOption | null>(null);
  const [searchResults, searchResultsSet] = React.useState<FinderLocation[]>([]);
  const [searchValue, searchValueSet] = React.useState("");
  const placeId = React.useRef<null | string>(null);
  const placeIdQuery = Array.isArray(query.placeId) ? query.placeId[0] : query.placeId;
  const productsQueryInitial = products.join(",");
  const userInteracted = React.useRef(false);

  React.useEffect(() => {
    if (placeId.current !== placeIdQuery && country && language) {
      if (placeIdQuery) {
        placeId.current = placeIdQuery;

        const searchParams = new URLSearchParams({
          country,
          language,
          placeId: placeIdQuery
        });
        fetch(`/api/location-details?${searchParams.toString()}`)
          .then((response) => {
            if (response.status >= 400) {
              throw new Error("Failed to fetch place.");
            }
            return response;
          })
          .then((response) => response.json())
          .then((data) => {
            placeSet(data);
            locationSelectedSet(null);
            searchResultsSet([]);

            if (!userInteracted.current) {
              searchValueSet(data.address);
            }

            userInteracted.current = false;
          })
          .catch(() => {
            placeSet({
              address: "Place not found.",
              id: "404",
              latitude: 0,
              longitude: 0
            });
            locationSelectedSet(null);
            searchResultsSet([]);
          });
      } else {
        placeSet(null);
        locationSelectedSet(null);
        searchResultsSet([]);
        searchValueSet("");
      }
    }
  }, [country, language, placeIdQuery]);

  React.useEffect(() => {
    if (place) {
      const productsQuery = searchType === "unbranded" ? "unbranded" : productsQueryInitial;

      searchingSet(true);

      const searchParams = new URLSearchParams({
        country: country as string,
        lat: String(place.latitude),
        lon: String(place.longitude),
        products: productsQuery,
        type: searchType,
        radius: radius?.toString() || ""
      });

      fetch(`/api/product-locations?${searchParams.toString()}`)
        .then((response) => {
          if (response.status >= 400) {
            throw new Error("Failed to fetch locations.");
          }
          return response;
        })
        .then((response) => response.json())
        .then((data) => {
          searchResultsSet(data.locations);
        })
        .catch(() => {
          searchResultsSet([]);
        })
        .finally(() => {
          searchingSet(false);
        });
    }
  }, [country, place, productsQueryInitial, radius, searchType]);

  const handleSearchOptionSelect = React.useCallback(
    (option: DropdownOption) => {
      userInteracted.current = true;
      searchOptionSelect(option);
      searchValueSet(option.label);
      push(
        {
          pathname: currentPathname,
          query: {
            ...query,
            placeId: option.value
          }
        },
        undefined,
        { scroll: false }
      );
    },
    [currentPathname, push, query]
  );

  const handleProductsSelect = React.useCallback(
    (productsNext: string[]) => {
      const productsFiltered = getProductsSelected({ products: productsNext.join(",") }, products);
      productsSelectedSet(productsFiltered);
      // Remove the products from the query in case all configuration has been removed.
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { products: productsPrev, ...queryOther } = query;
      replace(
        {
          query: {
            ...queryOther,
            ...(!!productsFiltered.length ? { products: productsFiltered.join(",") } : {})
          }
        },
        undefined,
        { scroll: false }
      );
    },
    [products, replace, query]
  );

  return (
    <FinderContext.Provider
      value={{
        locationSelected,
        locationSelectedSet,
        place,
        productsSelect: handleProductsSelect,
        productsSelected,
        searching,
        searchOptionSelect: handleSearchOptionSelect,
        searchOptionSelected,
        searchResults,
        searchType,
        searchValue,
        searchValueSet
      }}
    >
      {children}
    </FinderContext.Provider>
  );
};

export const useFinder = () => React.useContext(FinderContext);
