import { Box, Grid, GridColumn, ThemeOverride } from "@modernatx/ui-kit-react";
import React from "react";

import { useIsLoadingImages } from "@/hooks/useIsLoadingImages";
import { useIsLoadingPage } from "@/hooks/useIsLoadingPage";
import { BlockColumnsProps } from "@/types/Block";

import { BlockText } from "./BlockText";

type BlockColumn = BlockColumnsProps["columns"][0];

const useBlockColumnProps = (column: BlockColumn): React.ComponentProps<typeof GridColumn> => ({
  fillEnd: column.fillEnd,
  fillGapEnd: column.fillGapEnd,
  fillGapStart: column.fillGapStart,
  fillStart: column.fillStart,
  push: column.push,
  size: column.size,
  sx: {
    alignItems: column.alignX,
    backgroundColor: column.backgroundColor,
    backgroundImage: column.backgroundImage,
    backgroundPosition: column.backgroundPosition,
    backgroundRepeat: column.backgroundRepeat,
    backgroundSize: column.backgroundSize,
    borderBottomLeftRadius: column.borderBottomLeftRadius,
    borderBottomRightRadius: column.borderBottomRightRadius,
    borderRadius: column.borderRadius,
    borderTopLeftRadius: column.borderTopLeftRadius,
    borderTopRightRadius: column.borderTopRightRadius,
    boxShadow: column.boxShadow,
    gap: column.gap,
    justifyContent: column.alignY,
    mb: column.mb,
    mt: column.mt,
    outline: column.outline,
    outlineColor: column.outlineColor
  }
});

const BlockColumn: React.FC<BlockColumn> = (column) => {
  const props = useBlockColumnProps(column);

  if (column.themeMode) {
    return (
      <ThemeOverride mode={column.themeMode}>
        <GridColumn {...props}>
          <BlockText as="fragment" text={column.text} />
        </GridColumn>
      </ThemeOverride>
    );
  }

  return (
    <GridColumn {...props}>
      <BlockText as="fragment" text={column.text} />
    </GridColumn>
  );
};

const BlockColumnTransition: React.FC<BlockColumn> = (columnInitial) => {
  const [transitioning, transitioningSet] = React.useState(false);
  const column = React.useRef<BlockColumn>(columnInitial);
  const keyNext = `${JSON.stringify(columnInitial)}}`;
  const key = React.useRef(keyNext);
  const loadingImages = useIsLoadingImages({
    component: "Columns",
    props: { columns: [columnInitial] }
  });
  const loadingPage = useIsLoadingPage();
  const shouldTransition = key.current !== keyNext;
  const transitionTimeout = React.useRef<ReturnType<typeof setTimeout> | undefined>(undefined);

  React.useEffect(() => {
    if (shouldTransition) {
      transitioningSet(true);

      // We set a timeout to watch for the relative completion of the transition
      // since transitions can overlap, which makes listening on transition
      // complete lossy
      clearTimeout(transitionTimeout.current);

      transitionTimeout.current = setTimeout(() => {
        transitioningSet(false);
      }, 200);
    }
  }, [shouldTransition]);

  // Only update the children if the transition is complete to avoid content shifting
  // while the transition is occurring
  if (!transitioning && !loadingPage && !shouldTransition) {
    column.current = columnInitial;
  }

  const {
    sx: { backgroundImage, backgroundPosition, backgroundRepeat, backgroundSize, ...propsSx } = {},
    ...props
  } = useBlockColumnProps(column.current);

  key.current = keyNext;

  return (
    <GridColumn
      {...props}
      sx={{
        ...propsSx,
        position: "relative",
        transition: "background-color 200ms"
      }}
    >
      <Box
        sx={{
          backgroundImage,
          backgroundPosition,
          backgroundRepeat,
          backgroundSize,
          height: "100%",
          left: 0,
          opacity: transitioning || loadingImages || loadingPage ? 0 : 1,
          position: "absolute",
          top: 0,
          transition: "opacity 200ms",
          width: "100%"
        }}
      />
      <Box
        sx={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          opacity: transitioning || loadingImages || loadingPage ? 0 : 1,
          transition: "opacity 200ms"
        }}
      >
        <BlockText as="fragment" text={column.current.text} />
      </Box>
    </GridColumn>
  );
};

export const BlockColumns: React.FC<BlockColumnsProps> = ({
  backgroundColor,
  borderRadius,
  columns,
  wrap
}) => {
  const isScrollable = wrap === false;
  const styles = React.useMemo<React.ComponentProps<typeof Box>["sx"]>(
    () =>
      isScrollable
        ? {
            overflowY: "auto",
            scrollbarWidth: "none",
            width: "100%",
            // @ts-ignore
            "::-webkit-scrollbar": {
              display: "none"
            }
          }
        : {},
    [isScrollable]
  );

  return (
    <Box sx={styles} tabIndex={isScrollable ? 0 : undefined}>
      <Grid sx={{ backgroundColor: backgroundColor, borderRadius: borderRadius }} wrap={wrap}>
        {columns.map((column, i) =>
          column.transition ? (
            <BlockColumnTransition key={i} {...column} />
          ) : (
            <BlockColumn key={i} {...column} />
          )
        )}
      </Grid>
    </Box>
  );
};
