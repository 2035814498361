import { Box, BrandLogo, IconButton, NavPanel, Text, ThemeOverride } from "@modernatx/ui-kit-react";
import React from "react";

import { LabelHeaderBlockProps } from "@/types/Block";

import { LocaleSelector } from "../LocaleSelector/LocaleSelector";

export const LabelsHeader: React.FC<LabelHeaderBlockProps> = ({
  labelDesktop,
  labelMobile,
  logo,
  countryModalBlockProps,
  languageModalBlockProps
}) => {
  const [navPanelVisible, setNavPanelVisible] = React.useState(false);
  const handleNavPanelDismiss = React.useCallback(() => setNavPanelVisible(false), []);
  const handleNavPanelOpen = React.useCallback(() => setNavPanelVisible(true), []);

  return (
    <>
      {labelDesktop && labelMobile && (
        <ThemeOverride mode="dark">
          <Box
            sx={{
              backgroundColor: "background01",
              px: [5, 5, 8],
              py: 3,
              lineHeight: 3,
              fontSize: 1,
              width: "100%",
              minHeight: 11
            }}
          >
            <>
              <Box sx={{ display: ["none", "none", "block"] }}>
                <Text size="sm">{labelDesktop}</Text>
              </Box>
              <Box
                sx={{
                  display: ["flex", "flex", "none"],
                  justifyContent: "space-between",
                  alignItems: "center"
                }}
              >
                <Text size="sm">{labelMobile}</Text>
              </Box>
            </>
          </Box>
        </ThemeOverride>
      )}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          px: [5, 5, 8],
          paddingTop: 6,
          paddingBottom: [6, 6, 12],
          justifyContent: "space-between",
          width: "100%"
        }}
        role="navigation"
      >
        {logo && <BrandLogo logo={logo} sx={{ height: [7, 7, 10], width: "auto" }} />}
        <Box sx={{ display: ["none", "none", "flex"] }}>
          <LocaleSelector
            countryModal={countryModalBlockProps}
            languageModal={languageModalBlockProps}
            size="md"
          />
        </Box>
        <Box sx={{ display: ["flex", "flex", "none"] }}>
          <IconButton
            icon="list-view"
            variant="transparent"
            aria-label="menu-toggle"
            size="lg"
            onClick={handleNavPanelOpen}
          />
        </Box>
      </Box>
      <NavPanel visible={navPanelVisible} onDismiss={handleNavPanelDismiss}>
        <Box>
          <LocaleSelector
            countryModal={countryModalBlockProps}
            languageModal={languageModalBlockProps}
            size="lg"
          />
        </Box>
      </NavPanel>
    </>
  );
};
