import { Box, BrandLogo, IconButton, Target, Text } from "@modernatx/ui-kit-react";
import React from "react";

import { ProductsFooterBlockProps } from "@/types/Block";

const ProductsFooterLink: React.FC<React.ComponentProps<typeof Target>> = (props) => (
  <Target
    {...props}
    sx={{
      color: "text01",
      textDecoration: "underline",
      textDecorationColor: "transparent",
      transition: "colorMedium",
      py: 1,
      ":hover": { textDecorationColor: "text01" },
      ":focus-visible": {
        backgroundColor: "background02",
        borderRadius: "buttonBorderRadius"
      },
      ...props.sx
    }}
  />
);

export const ProductsFooter: React.FC<ProductsFooterBlockProps> = ({
  mainContent,
  socialIcons,
  subSection,
  locale = "en-US"
}) => {
  const year = React.useMemo(() => new Date().getFullYear(), []);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: [10, 10, 10],
        px: [4, 10, 16],
        py: [10, 10, 10]
      }}
    >
      {/* Wraps Links and socials */}
      {mainContent && (
        <Box
          sx={{
            display: "flex",
            flexDirection: ["column", "column", "row"],
            gap: [10, 10, 0],
            justifyContent: "space-between"
          }}
        >
          <Box sx={{ display: "flex", flexDirection: ["column", "row", "row"], gap: [6, 10, 16] }}>
            {mainContent?.map((section, i) => (
              <Box key={i} sx={{ display: "flex", flexDirection: "column" }}>
                <Text variant={"bold"} sx={{ textDecoration: "underline", pb: 1 }}>
                  {section.title}
                </Text>
                {section.links.map((link, j) => {
                  return (
                    <Box
                      key={j}
                      sx={{
                        display: "flex",
                        flexDirection: section.flexDirection,
                        gap: 2,
                        py: 1
                      }}
                    >
                      {link.title && (
                        <Text variant="bold" sx={{ pt: 1 }}>
                          {link.title}
                        </Text>
                      )}
                      {link.href ? (
                        <ProductsFooterLink key={j} href={link.href} target={link.target}>
                          <Box sx={{ display: "flex", flexDirection: "column" }}>
                            {link.text.map((textItem, k) => (
                              <Text key={k}>{textItem}</Text>
                            ))}
                          </Box>
                        </ProductsFooterLink>
                      ) : (
                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                          {link.text?.map((textItem, k) => <Text key={k}>{textItem}</Text>)}
                        </Box>
                      )}
                    </Box>
                  );
                })}
              </Box>
            ))}
          </Box>
          <Box
            sx={{
              alignItems: ["flex-start", "flex-start", "flex-end"],
              display: "flex",
              flexDirection: "column",
              gap: [5, 8, 8]
            }}
          >
            <Target href={`https://www.modernatx.com/${locale}`}>
              <BrandLogo logo="moderna-logo" sx={{ height: 8 }} />
            </Target>
            <Box sx={{ display: "flex", ml: [-2, -2, 0], mr: [0, 0, -2] }}>
              {socialIcons?.map((socialIcon, i) => (
                <IconButton
                  key={i}
                  aria-label={socialIcon.icon}
                  href={socialIcon.href}
                  icon={socialIcon.icon}
                  shape="square"
                  size="base"
                  target="_blank"
                  variant="transparent"
                />
              ))}
            </Box>
          </Box>
        </Box>
      )}
      <Box
        sx={{
          alignItems: ["flex-start", null, "center"],
          display: "flex",
          flexDirection: ["column", null, "row"],
          justifyContent: "space-between"
        }}
      >
        <Box sx={{ display: "flex", flexDirection: ["column", null, "row"] }}>
          {subSection?.links?.map((link, i, links) => (
            <ProductsFooterLink
              key={i}
              href={link.href}
              target={link.target}
              sx={{
                paddingInlineStart: i !== 0 ? [0, null, 5] : null,
                paddingInlineEnd: [0, null, 5],
                pb: i === links.length - 1 ? [5, null, 0] : [3, null, 0]
              }}
            >
              {link.text.join("\n")}
            </ProductsFooterLink>
          ))}
        </Box>
        <Text as="p">
          © {year} {subSection?.documentId}
        </Text>
      </Box>
    </Box>
  );
};
