import { Box, Button, Modal, ThemeOverride } from "@modernatx/ui-kit-react";
import React from "react";

import { SoftGatingModalBlockProps } from "@/types/Block";

import { BlockText } from "../BlockText";

export const SoftGatingModal: React.FC<SoftGatingModalBlockProps> = ({
  buttonText,
  text,
  footer,
  gatingType
}) => {
  const [softGatingVisible, softGatingVisibleSet] = React.useState(false);
  const CONFIRMED_VALUE = "1";

  React.useEffect(() => {
    const confirmedValue = localStorage.getItem(gatingType);
    softGatingVisibleSet(confirmedValue !== CONFIRMED_VALUE);
  }, [gatingType]);

  const handleClick = React.useCallback(() => {
    localStorage.setItem(gatingType, CONFIRMED_VALUE);
    softGatingVisibleSet(false);
  }, [gatingType, softGatingVisibleSet]);

  return (
    <Modal isOpen={softGatingVisible} size="sm">
      <Box sx={{ py: [5, 10, 15], px: [5, 10, 20] }}>
        <BlockText {...text} />
        <ThemeOverride mode="dark">
          <Button size="small" onClick={handleClick}>
            {buttonText}
          </Button>
        </ThemeOverride>
        {footer && <BlockText {...footer} />}
      </Box>
    </Modal>
  );
};
