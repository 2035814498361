import { useRouter } from "next/router";
import React from "react";

import { useExperience } from "@/context/ExperienceContext";
import { useExitModal } from "@/hooks/useExitModal";

const getMouseEventOpensInNewWindow = (event: React.MouseEvent) => {
  const target = event.currentTarget.getAttribute("target");

  return (
    (target && target !== "_self") ||
    event.metaKey ||
    event.ctrlKey ||
    event.shiftKey ||
    event.altKey ||
    (event.nativeEvent && event.nativeEvent.which === 2)
  );
};

export const useTargetProvider = () => {
  const { openExitModal, getIsExternalLink } = useExitModal();
  const { alternates, country } = useExperience();
  const { push, asPath } = useRouter();
  const alternateCountries = React.useMemo(
    () => alternates?.map((alternate) => alternate.country),
    [alternates]
  );

  const handleClick = React.useCallback(
    (e: React.MouseEvent) => {
      const href = e.currentTarget.getAttribute("href");
      if (e.currentTarget instanceof HTMLAnchorElement) {
        if (e.currentTarget.hasAttribute("data-exiting-link")) {
          openExitModal(false);
        } else if (href && getIsExternalLink(href)) {
          e.preventDefault();
          openExitModal(true);
        } else if (href?.startsWith("/") && !getMouseEventOpensInNewWindow(e)) {
          e.preventDefault();
          const scroll = e.currentTarget.getAttribute("data-scroll");

          if (!scroll) {
            push(href);
          } else {
            push(href, undefined, { scroll: false });

            const element = document.getElementById(scroll);

            if (scroll !== "false" && element && element.getBoundingClientRect().top <= 0) {
              document.getElementById(scroll)?.scrollIntoView();
            }
          }
        }
      }
    },
    [getIsExternalLink, openExitModal, push]
  );

  const resolveTarget = React.useCallback(
    (hrefInitial?: string) => {
      const isRelativeHref = hrefInitial?.startsWith("/") || hrefInitial?.startsWith("#");
      const href =
        isRelativeHref &&
        country === "us" &&
        asPath.startsWith("/us/") &&
        !alternateCountries?.some((alternateCountry) =>
          hrefInitial?.startsWith(`/${alternateCountry}/`)
        ) &&
        !hrefInitial?.startsWith("/assets/")
          ? `/us${hrefInitial}`
          : hrefInitial;
      const target = isRelativeHref ? "_self" : "_blank";

      return { href, target };
    },
    [alternateCountries, asPath, country]
  );

  return { handleClick, resolveTarget };
};
